<template>
  <div class="mini-weather-container">
    <b-img
      v-if="weatherIcon"
      class="weather-img"
      :src="imagePath"
      alt="Footer Image"
    ></b-img>
    <div class="weather-info">
      <div class="weather-city">{{ weatherCity }}</div>
      <span class="weather-value"
        >{{ weatherText }} <span class="weather-degree">{{ weatherValue }}°</span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["data"],
  computed: {
    ...mapGetters("weather", [
      "weatherCity",
      "weatherText",
      "weatherValue",
      "weatherIcon",
    ]),
    imagePath() {
      return `/images/weather/${this.weatherIcon}`;
    },
  },
};
</script>
<style scoped lang="scss">
.mini-weather-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .weather-img {
    width: 2.5vw;
    max-width: 100px;
    
  }
  .weather-info {
    text-align: start;
    margin-inline-start: 8px;
    .weather-city {
      font-size: clamp(5px, 1vw, 40px);
      line-height: 100%;
    }
    .weather-value {
      font-size: clamp(5px, 0.8vw, 33px);
      .weather-degree{
        margin-inline-start: 8px;
      }
    }
  }
}
</style>
